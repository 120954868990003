<template>
    <v-row
      no-gutters
      class="h-100"
    >
      <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
        <v-card
          class="w-100 rounded-lg py-4"
        >
          <v-card-title class="px-8">{{ $t('login.title') }}</v-card-title>
          <v-card-text class="px-8">
            <v-form
              ref="loginForm"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.email"
                    placeholder=" "
                    persistent-placeholder
                    autocomplete
                    :label="$t('login.email')"
                    outlined
                    required
                    dense
                    validate-on-blur
                    :rules="emailRules"
                    @keyup.enter="submitHandle"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.password"
                    type="password"
                    autocomplete
                    placeholder=" "
                    persistent-placeholder
                    name="input-10-1"
                    :label="$t('login.password')"
                    outlined
                    dense
                    validate-on-blur
                    :rules="passwordRules"
                    @keyup.enter="submitHandle"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-8 mb-4">
            <v-btn
              color="primary"
              block
              depressed
              :disabled="submitLoading"
              :loading="submitLoading"
              @click="submitHandle"
            >
              {{ $t('login.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- <div class="d-flex mt-4">
          <span class="mr-2">
            {{ $t('login.dontRememberPassword') }}
          </span>
          <span class="info--text pointer" @click="$router.push({ name: 'ResetPassword' })">
            {{ $t('login.changePassword') }}
          </span>
        </div> -->
      </v-col>
      <!-- <NewPassword ref="setNewPass" :email="form.email" :oldPassword="form.password" /> -->
    </v-row>
</template>
<script>
import Service from '@/services'
import * as mutationTypes from '@/store/mutation-types'
// import NewPassword from './NewPassword.vue'
import { mapActions } from 'vuex'

export default {
  // components: { NewPassword },
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ],
      emailRules: [
        v => !!v.trim() || this.$t('validation.emailRequired'),
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchUserData: 'fetchUserData'
    }),
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.submitLoading = true

      try {
        const { data } = await Service.post('Authentication/Login', {
          email: this.form.email,
          password: this.form.password
        })

        if (data.token) {
          localStorage.setItem('token', data.token)
          this.$store.commit(mutationTypes.LOGIN)
          await this.fetchUserData()
          await this.$router.push({ name: 'Home' })
        } else {
          this.$refs.setNewPass.show()
        }

        this.$refs.loginForm.resetValidation()
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
